@import url('https://fonts.googleapis.com/css2?family=Love+Light&family=Moon+Dance&display=swap');

/*
  Soft Pastel Pink: #FFC0CB - A classic, gentle pink that's perfect for creating a loving and inviting atmosphere.
  Light Cream: #FFFDD0 - A warm, subtle cream color that blends well with pastel pink, offering a comforting and soft background.
  Rose Petal Pink: #F9E0E3 - Slightly lighter and softer than the primary pastel pink, great for backgrounds or decorative elements to add depth without overwhelming.
  Romantic Blush: #FEA3AA - A slightly more vibrant pink to use for accents, buttons, or to highlight important sections like "The Big Question."
  Warm White: #FEF9EF - Ideal for text and UI elements, providing contrast against the softer background colors without being harsh.
  Subtle Lavender: #E3C9E9 - To add a hint of complementary color and sophistication to your palette, perfect for secondary buttons or highlights.
*/
:root {
  --soft-pastel-pink: #FFC0CB;
  --light-cream: #FFFDD0;
  --rose-petal-pink: #F9E0E3;
  --romantic-blush: #FEA3AA;
  --warm-white: #FEF9EF;
  --subtle-lavender: #E3C9E9;
  --heading-font: "Love Light", cursive;
  --body-font: 'Moon Dance', cursive;
  --heading-color: #FF0800;
  --body-color: #DC143C;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
} 

body {
  color: var(--body-color);
  font-family: var(--body-font);
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* collage */
/* collage */
.collage-container {
  display: grid;
  grid-auto-flow: column; /* Places new items in columns instead of rows */
  grid-template-rows: repeat(3, 1fr); /* Creates three rows */
  gap: 8px; /* Adjust the gap between images */
  animation: scrollHorizontal 30s linear infinite;
  align-items: start; /* Aligns items to the start of each cell */
  position: absolute;
  z-index: -1;
  height: 100vh; /* Set the height to the viewport height */
  width: max-content; /* Set the width to the content's width */
  overflow: hidden; /* Enables horizontal scrolling */
}

.collage-photo {
  width: auto;
  height: 30vh;
}

.collage-photo.rotate {
  transform: rotate(90deg);
  transform-origin: center center; /* Ensure the image rotates around its center */
  /* You may need to adjust height/width after rotation if the container doesn't fit the rotated aspect ratio */
}

@keyframes scrollHorizontal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-80% - 8px)); /* Subtract the total gap size if needed */
  }
}
